import Modal from 'components/elements/Modal';
import { X } from 'lucide-react';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { useSubscriptionStore } from 'modules/subscription/stores/subscriptionStore';
import { FindSVG } from 'modules/upsells/assets/FindSVG';
import { UpsellModalType } from 'modules/upsells/stores/types';

const TempWordLimitModal = ({ onCancel, open }: { onCancel: () => void; open: boolean; type: UpsellModalType }) => {
  const hdWordsGrant = useSubscriptionStore(subscriptionStoreSelectors.getHdWordsGrant);
  const nextHdWordsGrantReadableDate = useSubscriptionStore(subscriptionStoreSelectors.getNextHdWordsGrantReadableDate);

  return (
    <Modal classNames="md:max-w-[540px]" open={open} onClose={onCancel}>
      <div className="flex flex-col gap-spl-9 items-center relative justify-center px-[56px] py-[40px] bg-bg-primary">
        <div className="absolute top-2 right-2 [&_svg]:stroke-[var(--colors-text-primary)]">
          <button onClick={onCancel}>
            <X size={24} />
          </button>
        </div>
        <FindSVG />
        <div className="text-center text-text-primary space-y-2">
          <h3 className="text-heading-2">Temporary Word Limit Reached</h3>
          <p className="text-text-primary text-body-5">
            You have hit a rare milestone of {Intl.NumberFormat().format(hdWordsGrant)} Premium words, a limit few users reach. This is temporary and the limit
            will be lifted {nextHdWordsGrantReadableDate}. Alternatively, you can{' '}
            <a target="_blank" className="text-text-button-primary-cta-inverted" rel="noopener noreferrer" href="https://speechify.com/contact/">
              contact support
            </a>{' '}
            for further assistance.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default TempWordLimitModal;
